import React, {Component} from 'react';

class MyAccountPage extends Component {
  render() {
    return (
      <div>
        <h2>My Account</h2>
      </div>
    );
  }
}

export default MyAccountPage;

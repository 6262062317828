import React, {Component} from 'react';

class BlogPage extends Component {
  render() {
    return (
      <div>
        <h2>Blogs</h2>
      </div>
    );
  }
}

export default BlogPage;

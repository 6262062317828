import React from "react";
/* eslint-disable */
const Stars = () => (
  <div className="star">
    <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i
    className="fa fa-star"></i><i
    className="fa fa-star"></i>
  </div>
);

/* eslint-enable */
export default Stars;

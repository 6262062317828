import { combineReducers } from 'redux';
import fuelSavings from './fuelSavingsReducer';
import hotelReducer from './hotelReducer';
import searchReducer from './searchReducer';
import featuredBrandsReducer from './featuredBrandsReducer';
import featuredCitiesReducer from './featuredCitiesReducer';
import holidayTypesReducer from './holidayTypesReducer';
import featuredHolidaysReducer from './featuredHolidaysReducer';
import holidaysReducer from './holidayReducer';
import mainSliderReducer from './mainSliderReducer';
import suggestionReducer from './destinationReducer';

const rootReducer = combineReducers({
  fuelSavings,
  hotelReducer,
  searchReducer,
  featuredBrandsReducer,
  featuredCitiesReducer,
  holidayTypesReducer,
  featuredHolidaysReducer,
  holidaysReducer,
  mainSliderReducer,
  suggestionReducer
});

export default rootReducer;

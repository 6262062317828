import React from "react";
/* eslint-disable */
const SocialButtons = () => (
  <div className="icons">
    <i className="fa fa-book"></i><i className="fa fa-retweet"></i><i
    className="fa fa-comments"></i><i
    className="fa fa-map-marker"></i>
  </div>
);

/* eslint-enable */
export default SocialButtons;

export const CREATE_HOTEL = 'CREATE_HOTEL';

export const FETCH_FEATURED_CITIES_BEGIN = 'FETCH_FEATURED_CITIES_BEGIN';
export const FETCH_FEATURED_CITIES_SUCCESS = 'FETCH_FEATURED_CITIES_SUCCESS';
export const FETCH_FEATURED_CITIES_FAILURE = 'FETCH_FEATURED_CITIES_FAILURE';

export const FETCH_HOLIDAY_TYPES_BEGIN = 'FETCH_HOLIDAY_TYPES_BEGIN';
export const FETCH_HOLIDAY_TYPES_SUCCESS = 'FETCH_HOLIDAY_TYPES_SUCCESS';
export const FETCH_HOLIDAY_TYPES_FAILURE = 'FETCH_HOLIDAY_TYPES_FAILURE';

export const FETCH_FEATURED_BRANDS_BEGIN = 'FETCH_FEATURED_BRANDS_BEGIN';
export const FETCH_FEATURED_BRANDS_SUCCESS = 'FETCH_FEATURED_BRANDS_SUCCESS';
export const FETCH_FEATURED_BRANDS_FAILURE = 'FETCH_FEATURED_BRANDS_FAILURE';

export const FETCH_FEATURED_HOLIDAYS_BEGIN = 'FETCH_FEATURED_HOLIDAYS_BEGIN';
export const FETCH_FEATURED_HOLIDAYS_SUCCESS = 'FETCH_FEATURED_HOLIDAYS_SUCCESS';
export const FETCH_FEATURED_HOLIDAYS_FAILURE = 'FETCH_FEATURED_HOLIDAYS_FAILURE';

export const FETCH_MAIN_SLIDER_DATA_BEGIN = 'FETCH_MAIN_SLIDER_DATA_BEGIN';
export const FETCH_MAIN_SLIDER_DATA_SUCCESS = 'FETCH_MAIN_SLIDER_DATA_SUCCESS';
export const FETCH_MAIN_SLIDER_DATA_FAILURE = 'FETCH_MAIN_SLIDER_DATA_FAILURE';

export const SEARCH_HOLIDAY_BEGIN = 'SEARCH_HOLIDAY_BEGIN';
export const SEARCH_HOLIDAY_SUCCESS = 'SEARCH_HOLIDAY_SUCCESS';
export const SEARCH_HOLIDAY_FAILURE = 'SEARCH_HOLIDAY_FAILURE';

export const SUBMIT_SEARCH_SUCCESS = 'SUBMIT_SEARCH_SUCCESS';
export const LOAD_SEARCH_SUCCESS = 'LOAD_SEARCH_SUCCESS';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';


export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export const MAYBE_UPDATE_SUGGESTIONS = 'MAYBE_UPDATE_SUGGESTIONS';
export const LOAD_SUGGESTIONS_BEGIN = 'LOAD_SUGGESTIONS_BEGIN';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import HolidaysFilter from "./HolidaysFilter";
import Holidays from './Holidays';
import * as holidayActions from '../../actions/holidayActions';
import ErrorMessage from "../common/ErrorMessage";
import Loading from "../common/Loading";
import * as logger from '../../services/Logger';

class HolidaysPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      search: Object.assign({}, props.search)
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount(){
    this.props.dispatch(holidayActions.searchHoliday(this.state.search));
  }

  handlePageChange(pageNumber) {
    logger.info(pageNumber);
  }

  render() {
    const {error, loading, search, items} = this.props;
    if (error) {
      return <ErrorMessage message={error.message}/>;
    }

    if (loading) {
      return <Loading/>;
    }
    return (
      <div>
        <HolidaysFilter search={search} filter={search.filter}/>
        <Holidays items={items} error={error} loading={loading}
                  totalItemsCount={search.pagination.totalItemsCount}
                  activePage={search.pagination.currentPageIndex + 1}
                  itemsCountPerPage={search.pagination.pageSize}
                  pageRangeDisplayed={search.pagination.pageRangeDisplayed}
                  onPageChange={this.handlePageChange}/>
      </div>
    );
  }

}

HolidaysPage.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  search: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    error: state.holidaysReducer.error,
    loading: state.holidaysReducer.loading,
    search: state.holidaysReducer.search,
    items: state.holidaysReducer.items
  };
}


export default connect(mapStateToProps)(HolidaysPage);


import * as logger from './Logger';
import urls from '../urls';

/* eslint-disable no-undef */
function fetchJson(url) {
  let inputUrl = combinePath(urls.apiHost, url);
  return fetch(inputUrl, {
    accept: "application/json"
  })
    .then(checkStatus)
    .then(parseJSON);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  logger.log(error); // eslint-disable-line no-console
  //throw error;
  return response;
}

function parseJSON(response) {
  return response.json();
}

function combinePath(host, url) {
  let h = host.trim();
  let u = url.trim();
  if (!h.endsWith("/")) {
    h = h + "/";
  }
  if (u.startsWith("/")) {
    u = u.substring(1);
  }
  return h + u;
}

const Client = {
  fetchJson
};
export default Client;

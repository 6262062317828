import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as holidayActions from "../../actions/holidayActions";
import connect from "react-redux/lib/connect/connect";
// import * as logger from '../../services/Logger';

class HolidayRowItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      holiday: Object.assign({}, props.holiday)
    };

    this.addToWishList = this.addToWishList.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.holiday.id !== nextProps.holiday.id) {
      this.setState({holiday: Object.assign({}, nextProps.holiday)});
    }
  }

  addToWishList(event) {
    event.preventDefault();
    this.actions.addToWishList(this.state.holiday);
  }

  render() {
    let d = this.state.holiday;
    return (
      <div className="search-result-wide">
        <div className="image">
          <div className="ribbon-red"><span>TOP RATED</span></div>
          <img src={d.imgSrc} alt={d.name}/>
        </div>
        <div className="text">
          <h3>{d.name} <span><i className="fa fa-star"/><i className="fa fa-star"/><i
  className="fa fa-star"/><i className="fa fa-star"/><i className="fa fa-star"/></span></h3>
          <h4>{d.location.il_id}, {d.location.ilce} , {d.location.mevkii}</h4>
          <p>{d.description}</p>
          <div className="line">
            <div className="point">
              <span>{d.ratingPoint}</span>
            </div>
            <div className="results">
              <h3>BEST</h3>
              <h4>Evaluation result by {d.userCountRated} user</h4>
            </div>
            <div className="online">
              <i className="fa fa-desktop"/>
            </div>

          </div>
          <div className="result-icons">
            <Link to={'/'}>
                            <span className="fa-stack">
                                <i className="fa fa-circle fa-stack-2x top-back-circle-face"/>
                                <i className="fa fa-hotel fa-stack-1x fa-inverse"/>
                            </span>
            </Link>
            <Link to={'/'}>
                            <span className="fa-stack">
                                <i className="fa fa-circle fa-stack-2x top-back-circle-face"/>
                                <i className="fa fa-life-bouy fa-stack-1x fa-inverse"/>
                            </span>
            </Link>
            <Link to={'/'}>
                            <span className="fa-stack">
                                <i className="fa fa-circle fa-stack-2x top-back-circle-face"/>
                                <i className="fa fa-map-marker fa-stack-1x fa-inverse"/>
                            </span>
            </Link>
            <Link to={'/'}>
                            <span className="fa-stack">
                                <i className="fa fa-circle fa-stack-2x top-back-circle-face"/>
                                <i className="fa fa-tree fa-stack-1x fa-inverse"/>
                            </span>
            </Link>
          </div>
        </div>
        <div className="price">
          <h4>starts from</h4>
          <h3><span>$</span>{d.priceFrom}</h3>
          <Link to={"/holidays/" + d.id} className="button">DETAILS</Link>
        </div>
      </div>
    );
  }
}

HolidayRowItem.propTypes = {
  holiday: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

HolidayRowItem.contextTypes = {
  router: PropTypes.object
};

// function mapStateToProps(state) {
//   logger.info(state);
//   return {
//     // error: state.searchReducer.error,
//     // loading: state.searchReducer.loading,
//     //search: state.searchReducer
//   };
// }

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(holidayActions, dispatch)
  };
}

export default connect(mapDispatchToProps)(HolidayRowItem);

import React, {Component} from 'react';

class WishListPage extends Component {
  render() {
    return (
      <div>
        <h2>WishList</h2>
      </div>
    );
  }
}

export default WishListPage;

import React from 'react';


class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <h1>About</h1>
        <p>Vinn Travel Network Tourism</p>
      </div>
    );
  }
}

export default AboutPage;

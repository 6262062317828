import React, {Component} from 'react';

class BlogDetailPage extends Component {
  render() {
    return (
      <div>
        <h2>Blog Detail Page</h2>
      </div>
    );
  }
}

export default BlogDetailPage;

import * as searchHelper from "../services/SearchHelper";

const search = {
  startDate: null,
  endDate: null,
  focusedInput: null,
  rooms: searchHelper.generateSelectedOptions(1, 9),
  selectedRoom: {value: 1, label: 1},
  adults: searchHelper.generateSelectedOptions(1, 9),
  selectedAdult: {value: 1, label: 1},
  kids: searchHelper.generateSelectedOptions(0, 9),
  selectedKid: {value: 0, label: 0},
  destination: '',
  pagination: {
    currentPageIndex: 0,
    totalPages: 45,
    pageSize: 10,
    totalItemsCount: 450,
    pageRangeDisplayed: 5
  },
  filter: {
    hotelTypes: [
      {id: 1, name: "Hotel", count: 100, selected: false},
      {id: 2, name: "Hostel", count: 100, selected: false},
      {id: 3, name: "Apart Hotel", count: 100, selected: false},
      {id: 4, name: "Guest Hotel", count: 100, selected: false},
      {id: 5, name: "Apartment", count: 100, selected: false},
      {id: 6, name: "Residence", count: 100, selected: false}
    ],
    starCount: 5,
    selectedStar: null,
    mealTypes: [
      {id: 1, name: "BB", count: 100, selected: false},
      {id: 2, name: "HB", count: 100, selected: false},
      {id: 3, name: "HB+", count: 100, selected: false},
      {id: 4, name: "FB", count: 100, selected: false},
      {id: 5, name: "FB+", count: 100, selected: false},
      {id: 6, name: "AI", count: 100, selected: false},
      {id: 7, name: "UAI", count: 100, selected: false}
    ]
  }
};

export default {
  search: search,
  featuredBrands: {
    items: [],
    loading: false,
    error: null
  },
  featuredCities: {
    items: [],
    loading: false,
    error: null
  },
  featuredHolidays: {
    items: [],
    loading: false,
    error: null
  },
  holidayTypes: {
    items: [],
    loading: false,
    error: null
  },
  holidays: {
    search: search,
    items: [],
    loading: false,
    error: null
  },
  mainSliderData: {
    items: [],
    loading: false,
    error: null
  },
  fuelSavings: {
    newMpg: '',
    tradeMpg: '',
    newPpg: '',
    tradePpg: '',
    milesDriven: '',
    milesDrivenTimeframe: 'week',
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0
    }
  }
};
